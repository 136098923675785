/* You can add global styles to this file, and also import other style files */

@import 'assets/plugins/global/plugins.bundle.css';
@import 'assets/plugins/custom/prismjs/prismjs.bundle.css';
@import 'assets/css/style.bundle.css';

@import './app/shared/variables.scss';
@import '../../../node_modules/angular-calendar/css/angular-calendar.css';

@import 'assets/css/pages/wizard/wizard-3.css';

@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/themes/default.theme.css';
// @import "~@ng-select/ng-select/themes/material.theme.css";

// font-awesome
// @import '../../../node_modules/font-awesome/scss/font-awesome.scss';
@import 'assets/font-awesome/css/all.min.css';

body {
  height: 100%;
  width: 100%;
}

* {
  padding: 0;
  margin: 0;
}

.center {
  display: flex;
  justify-content: center;
}

.header .flex-column .header-fixed {
  justify-content: none !important;
}

.modal {
  z-index: 1075 !important;
}

.btn-primary,
.btn-light-primary  {
  background-color: $theme-color !important;
  border-color: $theme-color !important;
  
}

.symbol.symbol-primary .symbol-label {
  background-color: $theme-color !important;
}

.btn-outline-primary {
  border-color: $theme-color !important;
  color: $theme-color !important;
}

.menu-text,
.text-primary {
  color: $theme-color !important;
}



.dropdown-toggle {
  color: $theme-color !important;
  border-color: $theme-color !important;
}

.dropdown-toggle:hover {
  color: white !important;
  border-color: $theme-color !important;
  background-color: $theme-color !important;
}

.btn.btn-outline-primary.dropdown-toggle:after {
  color: $theme-color !important;
}

.btn.btn-outline-primary.dropdown-toggle:hover::after {
  color: white !important;
}

.show > .btn.btn-outline-primary.dropdown-toggle,
.show .btn.btn-outline-primary.btn-dropdown {
  background-color: $theme-color !important;
  color: white !important;
}

.show > .btn.btn-outline-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-primary.btn-dropdown.dropdown-toggle:after {
  color: white !important;
}

.btn.btn-light-primary  {
  color: $theme-color !important;
  background-color: $dynamic-alpha-colour !important;
}

.btn-light-primary:hover {
  color: white !important;
  background-color: $theme-color !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
  background-color: $theme-color !important;
  border-color: $theme-color !important;
}

.nav.nav-pills .show > .nav-link,
.nav.nav-pills .nav-link.active {
  background-color: $theme-color !important;
}

.bg-primary {
  background-color: $theme-color !important;
}

.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-outline-primary:focus:not(.btn-text),
.btn.btn-outline-primary.focus:not(.btn-text) {
  background-color: $theme-color !important;
  color: white !important;
}

.wh-100 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.max-height-200 {
  max-height: 200px !important;
  overflow: auto;
}

.invalid-border {
  border-color: red !important;
}

.w-840 {
  width: 840px !important;
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
    no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

/* Filtering table demo */
ngbd-table-filtering span.ngb-highlight {
  background-color: yellow;
}

/* Complete table demo */
ngbd-table-complete span.ngb-highlight {
  background-color: yellow;
}

ngb-carousel .picsum-img-wrapper {
  position: relative;
  height: 0;
  padding-top: 55%;
  /* Keep ratio for 900x500 images */
}

ngb-carousel .picsum-img-wrapper > img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

// Spinner
.cssload-speeding-wheel {
  content: '';
  display: block;
  position: absolute;
  left: 50vw;
  top: 50vh;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: solid 5px #cfd0d1;
  border-bottom-color: $theme-color !important;
  border-radius: 50%;
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  will-change: transform;
}

// End Spinner

.page-item.active .page-link {
  background-color: $theme-color !important;
  border-color: $theme-color !important;
}

.header-navs.header-navs-on {
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
}

.offcanvas-mobile.offcanvas-mobile-on {
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
  left: 0 !important;
}

// password Input
.password-input input {
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.password-input button {
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
}

.d-block.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

// input::-webkit-textfield-decoration-container {
//   display: none;
// }
